<template>
   <div>
       <h1>Let's get started!</h1>
       <ActionButtons :links=links></ActionButtons>
   </div>
</template>

<script>
import ActionButtons from '@/components/ActionButtons.vue'
export default {
    name: 'Budget',
    metaInfo: {
        title: 'Budget',
    },
    components: {
        ActionButtons,
    },
    data: () => ({
        links: [
            {
                title: 'Plan',
                url: '/plan',
                cols: 6,
                md: 4,
            },
            {
                title: 'Save',
                url: '/save',
                cols: 6,
                md: 4
            },
            {
                title: 'Import',
                url: '/import',
                cols: 6,
                md: 4,
            },
            {
                title: 'Set up Accounts',
                url: '/account',
                cols: 6,
                md: 12,
            }
        ]
    })
}
</script>
